.token__title {
    text-align: center;
    margin-bottom: 0rem;
}

.token__subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
}

h3 {
    text-align: center;
}

.parent-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.accounts-container,
.balances-container {
    border: 4px solid black;
    display: flex;
    flex-wrap: wrap;  
    border-radius: 1rem;
    margin: 2rem;
    margin-left: 4rem;
    margin-right: 4rem;
    width: 600px;
    height: 65%;
}

.account-card,
.balance-card {
    padding: 10px;
    margin: 10px;
    flex: 1 0 0;
}

.transactions-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border: 4px solid black;
    margin: 2rem;
    margin-left: 4rem;
    margin-right: 4rem;
    border-radius: 1rem;
}
  
.transaction-card {
    flex: 0 0 calc(25% - 20px) !important;
    padding: 0.5rem;
    margin: 1rem;
}

.bold-label {
    font-weight: bold;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 4rem;
}
  
.page-button {
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: #333;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    margin-bottom: 2rem;
}
  
.page-button:hover {
    background-color: #444;
}

.modal-h2 {
    margin-top: 0rem;
}

.modal-connect {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-c {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-d {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content-connect {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 600px;
    height: 300px;
    text-align: center;
    overflow-y: auto;
}
  
.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 600px;
    height: 400px;
    text-align: center;
    overflow-y: auto;
}

.modal-content-c {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 600px;
    height: 400px;
    text-align: center;
    overflow-y: auto;
}

.modal-content-d {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 600px;
    height: 400px;
    text-align: center;
    overflow-y: auto;
}

.yes, .no {
    background-color: #333;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    margin: 1rem;
}

.yes- {
    background-color: #333;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    margin: 1rem;
    margin-top: 3rem;
}

.yes:hover, .no:hover {
    background-color: #444;
}

.modal p {
    margin-top: -2rem;
}

.logout-btn, .connect-btn {
    background-color: #333;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    margin-top: 2rem;
}

.logout-btn:hover, .connect-btn:hover {
    background-color: #444;
}

.connect-container, .logout-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.eb-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
}

.logout-btn {
    margin-top: 1rem;
}

.expense-label, .budget-label {
    font-weight: bold;
}

.budget-label, .budget-detail {
    font-size: 20px;
}

.expense-item, .budget-item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.expense-total, .budget-total {
    border-top: 1px solid #000;
    padding-top: 10px;
    margin-top: 10px;
}

.expense-total-2 {
    border-bottom: 1px solid #000;
    font-size: 17px;
    margin-bottom: 2.5rem;
}

.budget-item {
    margin-bottom: 3rem;
    border-bottom: 1px solid #000;
}

.budget-detail {
    margin-bottom: 0rem;
}

.budget-total-label {
    font-weight: bold;
    font-size: 18px;
}

.label {
    font-weight: bold;
    display: block;
}

.label span {
    margin-right: 1rem;
}

.label input {
    width: 200px;
    height: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid #333;
}

.trash-btn {
    background-color: white;
    color: black;
    padding: 1rem 2rem;
    border: none;
    cursor: pointer;
    margin-top: -3.75rem; 
}

.expense-detail, .budget-detail {
    margin-top: -2rem;
}

/* ===================== BREAKPOINTS ===================== /

/*     Tablet Devices      */
@media screen and (min-width: 476px) and (max-width: 1380px) {
    .parent-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

/*      Mobile Devices      */
@media screen and (max-width: 475px) {
    .token__title,
    .token__subtitle,
    h3,
    .account-card,
    .balance-card,
    .button-container,
    .page-button,
    .modal-connect,
    .modal,
    .modal-c,
    .modal-d,
    .modal-content-connect,
    .modal-content,
    .modal-content-c,
    .modal-content-d,
    .yes,
    .no,
    .yes-,
    .logout-btn,
    .connect-btn,
    .connect-container,
    .logout-container,
    .eb-container,
    .expense-item,
    .budget-item,
    .label input {
        width: calc(100% - 2rem);
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .parent-container,
    .accounts-container,
    .balances-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: auto;
        padding: 0 2rem;
    }

    .balances-container {
        width: 220%;
        margin-left: -10rem;
        margin-right: 0rem;
    }

    .transaction-card {
        flex-basis: 40%;
    }

    .transactions-container {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .modal-content-connect,
    .modal-content,
    .modal-content-c,
    .modal-content-d {
        width: 90%;
        height: 50%;
        margin-top: 5rem;
    }

    .modal-content-connect {
        height: 38%;
    }

    .logout-btn,
    .connect-btn {
        margin-top: 1rem;
    }
   
}