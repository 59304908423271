.footer {
    background-color: black;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
}

.footer p {
    color: white;
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
    margin: 0;
    padding: 1rem;
}

.footer a {
    color: white;
    text-decoration: underline;
    font-weight: normal;
}