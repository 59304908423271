body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
}
 
.landing-container {
    display: flex;
    margin-top: -3rem;
}
 
.landing-image {
    width: 50%;
    height: 100vh;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    order: 2;
} 
 
.landing-content {
    height: 100vh;
    width: 50%;
    padding-left: 2rem;
    margin-left: 4rem;
    order: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
 
.landing-title {
    color: black;
    margin-bottom: 0rem;
}
 
.landing-subtitle {
    margin-top: 0rem;
    margin-bottom: 1rem;
    color: black;
}
 
.get-started-button {
    background-color: #333;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
}
 
.get-started-button:hover {
    background-color: #444;
}

/* Mobile Devices */
@media screen and (max-width: 768px) {
    .landing-container {
        height: 100vh;
        flex-direction: column;
    }
    .landing-content {
        justify-content: start;
    }

    .landing-title {
        margin-top: 0;
    }

    .landing-subtitle {
        margin-top: 1.5rem;
        margin-bottom: 2rem;
    }
 
    .landing-text {
       text-align: center;
       padding-left: 95%;
       order: 1;
       margin-left: -6rem;
    }

    .landing-text h1 {
        width: 100%;
        font-size: 3.5rem;
        margin-left: auto;
        margin-right: auto;
    }

    .landing-text h2 {
        width: 100%;
        font-size: 2rem;
    }
 
    .landing-image {
       width: 100%;
       height: 60vh;
       margin-top: 6rem;
       order: -1;
    }
 }

 /* Tablet Devices */
 @media screen and (min-width: 476px) and (max-width: 999px) {
    .landing-container {
        height: 100vh;
        flex-direction: column;
    }

    .landing-content {
        justify-content: start;
        margin-left: 2rem;
        width: 100%;
    }

    .landing-text {
        width: 100%;
        text-align: center;
        padding-left: 15%;
        padding-right: 15%;
        order: 1;
    }

    .landing-text h1 {
        margin-top: 0;
    }

    .landing-image {
        width: 100%;
        margin-top: 10%;
        order: -1;
    }
 }

 /* Large Devices */
 @media screen and (min-width: 1500px) {
    .landing-image {
        margin-top: 5rem;
    }
}