/* This CSS rule sets the default font size of the entire document */
html {
    font-size: 16px;
  }
  
  /* These CSS rules sets the text direction of the paragraph (RTL or LTR) */
  p,
  .bpw-from-bot > div,
  #input-message {
    direction: ltr;
  }
  
  /* This rule changes the color of the text in the input message */
  #input-message {
    color: #000000;
  }
  
  /* Changes the color of the new message indicator */
  .bpw-new-messages-indicator {
    background-color: #f5f5f5;
    color: #ffffff;
  }
  
  /* Styling for the chat header container */
  .bpw-header-container {
    margin: 10px;
    background: #6675fa;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    border: 1px;
    border-radius: 15px;
    color: #ffffff;
  }
  
  .bpw-header-name{
    color: #ffffff;
  }
  
  /* Styling for the typing bubble */
  .bpw-typing-bubble {
    background: #000000;
  }
  
  /* Styling for the chat bubble content */
  .bpw-chat-bubble-content {
    background-color: #f5f5f5;
    border-radius: 3px;
    border: 1px transparent;
  }
  
  /* Styling for the date container, header name, and header subtitle */
  .bpw-date-container,
  .bpw-header-name,
  .bpw-header-subtitle {
    color: #ffffff;
  }
  
  /* Styling for the layout of the chat bubble. Width, height, border, position and radius of the chat bubble */
  .bpw-layout {
    width: 360px !important;
    height: 45vh;
    border-radius: 10px;
    right: 10px;
    bottom: 10px;
    border: 1px;
  }

  /* Responsive design rules for devices with width less than or equal to 767px */
  @media screen and (max-device-width: 767px) {
    .bpw-layout {
      width: 100% !important;
      height: 80%;
      right: 0;
      bottom: 0;
      border-radius: 0;
    }
  }
  
  /* Changes the fill color of the header icons to white */
  .bpw-header-icon,
  .bpw-header-icon svg,
  .bpw-header-icon svg path {
    fill: #ffffff !important;
  }
  
  /* Changes the color of the placeholder text in the input message */
  #input-message::placeholder {
    color: rgba(0,0,0,.30);
  }

  
  .bpw-composer textarea{
    outline: none !important;
    border: 1px solid rgba(0,0,0,.15);
  }
  .bpw-composer textarea:focus{
    outline: none !important;
    border: 1px solid rgba(0,0,0,.30);
  }
  
  /* Styling for the keyboard single choice option */
  .bpw-keyboard-single-choice {
    background-color: #ffffff;
    border: none;
  }
  
  /* Styling for the buttons in the chat interface */
  .bpw-button,
  .bpw-button-alt {
    background-color: #dcdcdc;
    color: #000000;
    border-radius: 10px;
    border: none;
  }
  
  
  /* Styling for hover effect on the buttons in the chat interface */
  .bpw-button:hover,
  .bpw-button-alt:hover {
    background-color: #f5f5f5;
  }
  
  
  /* Styling for hyperlinks in the chat interface */
  a {
    color: #ffffff;
    text-decoration: underline;
  }
  
  /* Styling for the chat container, including color, scrollbar width and color, and border */
  .bpw-chat-container {
    background-color: #ffffff;
    scrollbar-width: thin;
    scrollbar-color: #f5f5f5 #ffffff;
    border: none;
  }
  
  /* Styling for the chat bubble content when it's from the bot */
  .bpw-from-bot .bpw-chat-bubble .bpw-chat-bubble-content {
    background-color: #f5f5f5;
    color: #000000;
  }
  
  /* Styling for the chat bubble content when it's from the user */
  .bpw-from-user .bpw-chat-bubble .bpw-chat-bubble-content {
    background-color: #6675fa;
    color: #ffffff;
  }
  
  /* Styling for the composer section of the chat interface */
  .bpw-composer {
    background-color: #ffffff;
    border-top: none;
  }
  
  
  /* Styling for the scrollbar in the chat container */
  .bpw-chat-container::-webkit-scrollbar,
  .bpw-chat-container::-moz-scrollbar {
    width: 10px;
    background-color: #ffffff;
    border: none;
  }
  
  /* Styling for the avatar of the bot */
  .bpw-bot-avatar img,
  .bpw-bot-avatar svg {
    background: #000000;
    border: 3px solid #ffffff;
  }
  
  /* Styling for the general scrollbar in the web page */
  ::-webkit-scrollbar {
    width: 0.5rem;
  }
  
  /* Styling for the track of the general scrollbar and the chat container scrollbar */
  ::-webkit-scrollbar-track,
  .bpw-chat-container::-webkit-scrollbar-track,
  .bpw-chat-container::-moz-scrollbar-track {
    background-color: transparent;
  }
  
  /* Styling for the thumb of the general scrollbar and the chat container scrollbar */
  ::-webkit-scrollbar-thumb,
  .bpw-chat-container::-webkit-scrollbar-thumb,
  .bpw-chat-container::-moz-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 1rem;
    border: 0.5rem solid transparent;
  }
  
  /* Styling for the floating button icon in the chat interface */
  .bpw-floating-button i svg path {
    fill: #6675fa;
  }
  
  
  
  /* Styling for the 'powered by' section of the chat interface */
  .bpw-powered {
    text-align: center;
    padding: 10px;
    color: #000000;
    background: #ffffff;
    font-size: 14px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  /* Styling for the hyperlinks in the 'powered by' section of the chat interface */
  .bpw-powered a {
    color: #000000;
    text-decoration: underline;
  }
  
  /* Styling for hover effect on the hyperlinks in the 'powered by' section of the chat interface */
  .bpw-powered a:hover {
    text-decoration: underline;
  }
  
  .bpw-send-button{
    background: #6675fa;
  }
  
  
  /* Change Bot Widget Icon */
  
  .bpw-widget-btn{
    border-radius: 50%;
    background:#6675fa;
  }
  
  .bpw-floating-button::before {
  background:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='white' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
    background-position: center;
  background-size: contain;
  height:undefinedpx !important;
  width:undefinedpx !important;
  }
  
  .bpw-floating-button {
    box-shadow: none !important;
  }
  
  .bpw-floating-button:hover {
    box-shadow: none !important;
  }
