@import "~plaid-threads/scss/variables";

.grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 2 * $unit;
}

.title {
  text-align: center;
  margin-top: 8rem;
  margin-bottom: 0;
  font-weight: 800;
  height: 6 * $unit;
}

.subtitle {
  text-align: center;
  margin-top: 0;
  margin-bottom: 3 * $unit;
}

.introPar {
  width: 100%;
  font-size: 2 * $unit;
  margin: 2 * $unit 0;
  text-align: center;
}

.linkButton {
  margin-top: 3 * $unit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemAccessContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  box-shadow: $shadow-small;
  margin-top: 3 * $unit;
  border-radius: 2px;
}

.itemAccessRow {
  display: flex;
  border: 1px solid $black200;
  height: 50%;
  margin: 0;

  &:last-child {
    border-top: 0px;
  }
}

.idName {
  padding: 2 * $unit 3 * $unit 2 * $unit 5 * $unit;
  flex: 1;
  font-weight: bold;
  font-family: $font-stack-monospace;
  color: $black1000;
}

.tokenText {
  padding: 2 * $unit 3 * $unit 2 * $unit 0;
  flex: 5;
  font-family: $font-stack-monospace;
}

.requests {
  margin-top: 7 * $unit;
  font-size: 2 * $unit;
}

/* Mobile Devices */
@media screen and (max-width: 768px) {
  .title {
    margin-left: -5rem !important;
  }

  .subtitle {
    margin-left: -13rem !important;
  }

  .linkButton {
    margin-left: -14rem !important;
  }
}

/* Tablet Devices */
@media screen and (min-width: 476px) and (max-width: 999px) {
  .title {
    margin-left: -15rem !important;
  }

  .subtitle {
    margin-left: -14rem !important;
  }
}
