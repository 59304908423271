.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.contact__title {
    text-align: center;
    margin-top: 0rem;
}

.contact__subtitle {
    text-align: center;
}

.form-container {
    margin-top: 3rem;
    border: 4px solid black;
    padding: 3rem;
    border-radius: 8px;
    width: 500px;
    background-color: whitesmoke;
}

.contact {
    text-align: center;
}

.contact h2 {
    font-size: larger;
    margin-top: -0.5rem;
}

.name, .email, textarea {
    text-align: left !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid #333;
}

.name::placeholder, .email::placeholder, textarea::placeholder {
    text-align: left;
    font-family: inherit;
}

.name:focus, .email:focus, textarea:focus {
    outline: none;
}

.contact__button {
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: white;
    background-color: #333;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 1rem;
    margin-top: 2rem;
    border-radius: 5px;
    border: none;
}

.contact__button:hover {
    background-color: #555;
}

/* Mobile Devices */
@media screen and (max-width: 475px) {
    .contact__title {
        font-size: 5rem;
    }
}

 /* Tablet Devices */
 @media screen and (min-width: 476px) and (max-width: 999px) {
    .form-container {
        margin-bottom: 8rem;
    }
 }

